import NetworkPicker from "views/NetworkPicker";

export const Header = () => (
  <div className="so-back" data-testid="page-header">
    <div className="so-chunk">
      <div className="so-siteHeader LaboratoryChrome__header">

        <NetworkPicker />
      </div>
    </div>
  </div>
);
