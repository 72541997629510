// This standardizes all the signatures verification states
// into a single map.
const FETCHED_SIGNERS = {
  NONE: 'NONE',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  NOT_EXIST: 'NOT_EXIST',
};
export default FETCHED_SIGNERS;
